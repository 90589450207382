
const createCaptchaScript = () => {
  const scriptCaptcha = document.createElement('script');
  scriptCaptcha.type = 'text/javascript';
  scriptCaptcha.src = '//app.icontact.com/icp/static/form/javascripts/validation-captcha.js';
  scriptCaptcha.id = 'icontactScript';
  scriptCaptcha.async = true;
  scriptCaptcha.onload = () => window.$_ICV.init();

  document.body.appendChild(scriptCaptcha);
};

const initIContactScript = () => {
  /**
   * Extra security measure to check if the script has
   * already been included in the DOM
   */
  if (document.getElementById('icontactScript')) {
    // iContact function recieved from request
    window.$_ICV.init();
  }else {
    createCaptchaScript();
  }
};

export default initIContactScript;
