import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import Layout from '../../components/layout';
import HeadHelmet from '../../components/head';
import { StyledBackground } from '../../components/ui-elements/backgroundImage';
import styled from 'styled-components';
import useWindowSize from '../../hooks/useWindowSize';
import initIContactScript from '../../utils/initIContactScript';
import { GetQuoteDiv } from '../../components/pages/solutions/getQuoteDiv';

const SlideWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  height: auto;
  ul {
    color: black;
  }
`;

const FormWrapper = styled.div`
  @media (min-width: 0) {
    width: 100%;
    margin: 3rem auto;
    border: none;

    h3 {
      font-size: 22px;
    }
    form {
      display: inline-block;
      text-align: left;
    }
    label {
      display: inline-block;
      font-family: Lato, sans-serif;
      margin: 0.5rem;
      color: var(--base-color);
      font-size: 16px;
      text-align: left;
    }
    input {
      height: 20px;
      margin: 0.5rem;
      margin-left: 0;
      background-color: #ffffff;
      font-size: 20px;
      padding-left: 10px;
      border-radius: 4px 4px 4px 4px;
      box-shadow: 0 3px 6px rgba(0, 47, 108, 0.3);
    }

    input[type='radio'] {
      border-radius: 0;
      height: 13px;
      margin-left: 0.5rem;
    }

    section {
      width: 90%;
      margin: 0 auto;
    }

    fieldset {
      border: none;
      display: inline-block;
      font-family: Lato, sans-serif;
      margin: 0.5rem;
      padding: 0;
      color: var(--base-color);
      font-size: 16px;
      text-align: left;
    }

    .button-div {
      text-align: center;
      margin: 3rem 0;
    }
  }
  @media (min-width: 960px) {
    width: 50%;
    border: 2px solid var(--base-color);
    padding: 0 3rem;
  }
`;

const FirstSectionWrapper = styled.div`
  .getAQuote {
    a {
      font-size: 20px;
      font-weight: bold;
      text-decoration: none;
      color: var(--base-color);
      text-align: center;
      border-radius: 22px;
      box-shadow: 0 2px 10px #002f6c33;
      background-color: #fff;
      padding: 0.5rem 2rem;
    }
  }
`;

const SecondSectionWrapper = styled.div`
  background-color: #f7f9fa;
  padding: 1rem 0;
  div:first-child {
    margin-top: 0 !important;
  }

  ul {
    @media (min-width: 0) {
      columns: 1;
      overflow-wrap: break-word;
    }
    @media (min-width: 550px) {
      columns: 2;
      overflow-wrap: break-word;
    }
    @media (min-width: 960px) {
      columns: 3;
      column-gap: 50px;
    }
    @media (min-width: 1440px) {
      columns: 4;
    }
    li {
      font-family: Lato, sans-serif;
      font-size: 18px;
      text-align: left;
      list-style: none;
      padding: 0.5rem 0;
    }
  }
`;

const ThirdSectionWrapper = styled.div``;

const FourthSectionWrapper = styled.div`
  border: 2px solid lightgrey;
  display: flex;
  width: 80%;
  margin: 2rem auto;
  flex-direction: column;
  .title-wrapper {
    h2 {
      font-size: 24px;
      @media (min-width: 1024px){
        font-size: 32px;
      }
      @media (min-width: 1440px){
        font-size: 40px;
      }
    }
  }
  .content-wrapper {
    width: 80%;
    margin: 0 auto;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .button-div {
      width: 100%;
      text-align: center;
      margin: 0 auto 1rem auto;
      input.Services__blueButton {
        width: 100%;
      }
      @media (min-width: 1024px){
        width: 19%;
        input.Services__blueButton {
          width: auto;
        }
      }
    }
  }
  .input-wrapper {
    //  margin-right: 1.5rem;
    width: 100%;
    @media (min-width: 1024px){
      width: 77%;
    }
    input {
      box-sizing: border-box;
      width: 100%;
      color: #60616F;
      font-size: 14px;
      font-style: italic;
      border-radius: 5px;
      padding: 5px;
      //padding: 20px 20px 20px 30px;
      border: 1px solid lightgray;
      @media (min-width: 400px){
        padding: 10px 10px 10px 15px;
      }
      @media (min-width: 690px){
        // width: 100%;
        padding: 20px 20px 20px 30px;
        font-size: 18px;
      }
    }
    p {
      box-sizing: border-box;
      width: 100%;
      margin: 1rem auto;
      padding: 0 3px;
      font-size: 12px;
      font-style: italic;
      color: #60616F;
      @media (min-width: 1440px){
        text-align: left;
      }
    }
    @media (min-width: 866px){
      margin-right: 1rem;
    }
  }
`;

const RestaurantEquipment = ({ data, location }) => {

  useEffect(() => {
    initIContactScript();
  }, []);

  const { strapiSsRestaurantEquip } = data;

  const {
    MetaData,
    title,
    sectionUnderHero,
    hero_image,
    description,
    secondSectionTitle,
    secondSection,
    thirdSection
  } = strapiSsRestaurantEquip || {};

  const itemsC = secondSection.split('- ');

  const { width } = useWindowSize();
  let perChunk = width < 961 ? 12 : 24; // items per chunk in alice-carousel

  let chunkArrays = itemsC.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / perChunk);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);
  const ITEMS = chunkArrays.map((array, i) => {
    return (
      <SlideWrapper key={i}>
        <ul>
          {array.map((item) => {
            return <li key={item}>{item}</li>;
          })}
        </ul>
      </SlideWrapper>
    );
  });

  const [radioValue1, setRadioValue1] = useState('');
  const [radioValue2, setRadioValue2] = useState('');
  const [radioValue3, setRadioValue3] = useState('');

  const handleRadioChange1 = (event) => {
    setRadioValue1(event.target.value);
  };

  const handleRadioChange2 = (event) => {
    setRadioValue2(event.target.value);
  };

  const handleRadioChange3 = (event) => {
    setRadioValue3(event.target.value);
  };
  return (
    <>
      <HeadHelmet data={ MetaData } />

      <Layout>
        <StyledBackground
          img={ hero_image?.childImageSharp.fluid }
          title={ title }
          location={ location.pathname }
          titleWidth="100%"
        />
        <GetQuoteDiv>
          <ReactMarkdown>{ sectionUnderHero }</ReactMarkdown>
        </GetQuoteDiv>
        <div className={'SharedEl__textContent--center'}>
          <FirstSectionWrapper>
            <ReactMarkdown className={'getAQuote'}>
              { description }
            </ReactMarkdown>
          </FirstSectionWrapper>
        </div>

        <SecondSectionWrapper>
          <div className={'SharedEl__textContent--center'}>
            <h2>{ secondSectionTitle }</h2>
            <AliceCarousel
              disableDotsControls={false}
              disableButtonsControls={true}
              items={ITEMS}
              mouseTracking
              autoPlay
              autoPlayInterval={4000}

            />
          </div>
        </SecondSectionWrapper>

        <FourthSectionWrapper>
          {  <form
            id='ic_signupform'
            captcha-key='6LeCZCcUAAAAALhxcQ5fN80W6Wa2K3GqRQK6WRjA'
            captcha-theme='light'
            new-captcha='true'
            method='POST'
            action='https://app.icontact.com/icp/core/mycontacts/signup/designer/form/?id=664&cid=1791092&lid=33433'
          >
            <div className={'title-wrapper'}>
              <h2>Get your copy of our catalog</h2>
            </div>
            <div className={'content-wrapper'}>
              <div
                className={'input-wrapper'}
                data-validation-type='1'
                data-label='Email'
              >
                <input
                  type='text'
                  required
                  placeholder='Enter email address'
                  name='data[email]'
                />
                <p>By providing your email address you are agreeing to recive updates, news and offers from Stanford
                  Sonoma.</p>
              </div>
              <div
                className='formEl fieldtype-checkbox required'
                dataname='listGroups'
                data-validation-type='1'
                data-label='Lists'
                style={{ display: 'none', width: '100%' }}
              >
                <h3>Lists<span className='indicator required'>*</span></h3>
                <div className='option-container'>
                  <label className="checkbox">
                    <input
                      type="checkbox" alt=""
                      name="data[listGroups][]"
                      value="93324"
                      checked
                    />Newsletter Sign Ups
                    </label>
                </div>
              </div>
              <div className={'button-div submit-container'}>
                <input className={'Services__blueButton'} type='submit' value='Submit' />
              </div>
              <div className='hidden-container'></div>
            </div>
          </form>
          }
        </FourthSectionWrapper>
        <img src="//app.icontact.com/icp/core/signup/tracking.gif?id=664&cid=1791092&lid=33433" alt={''} />

        <div className={'SharedEl__textContent--center'}>
          <ThirdSectionWrapper>
            <ReactMarkdown>{ thirdSection }</ReactMarkdown>
          </ThirdSectionWrapper>
        </div>

        <div style={{ textAlign: 'center' }}>
          <FormWrapper>
            <form
              method="POST"
              name="Leasing&Financing"
              data-netlify-honeypot="bot-field"
              data-netlify="true"
              action="/thank-you"
            >
              <input type="hidden" name="form-name" value="Leasing&Financing" />
              <input
                type="hidden"
                name="subject"
                value="Leasing&Financing form submission from stanfordsonoma.com"
              />

              <section>
                <h3>Applicant Information</h3>

                <div style={{ display: 'block', margin: '.5rem auto', textAlign: 'left' }}>
                  <label>
                    Legal Business Name: <br />
                    <input type="text" name="legal_bussiness_name" />
                  </label>
                  <label>
                    Years in Business: <br />
                    <input type="number" name="years_in_business" />
                  </label>

                  <label>
                    # of Employees: <br />
                    <input type="number" name="years_in_business" />
                  </label>
                </div>

                <div style={{ display: 'block', margin: '.5rem auto', textAlign: 'left' }}>
                  <label>
                    Trade Name (DBA) <br />
                    <input type="text" name="trade_name_dba" />
                  </label>
                  <label>
                    Annual Sales: <br />
                    <input type="text" name="annual_sales" />
                  </label>
                  <label>
                    FED ID # <br />
                    <input type="text" name="fed_id" />
                  </label>
                </div>

                <div style={{ display: 'block', margin: '.5rem auto', textAlign: 'left' }}>
                  <label>
                    Type of Business: <br />
                    <input type="text" name="type_of_business" />
                  </label>
                  <label>
                    Phone: <br />
                    <input type="text" name="phone" />
                  </label>
                </div>

                <div style={{ display: 'block', margin: '.5rem auto', textAlign: 'left' }}>
                  <label>
                    Email: <br />
                    <input type="email" name="email" />
                  </label>
                  <label>
                    Cell: <br />
                    <input type="text" name="cell_phone" />
                  </label>
                </div>

                <div style={{ display: 'block', margin: '.5rem auto', textAlign: 'left' }}>
                  <label>
                    Mailing Address: <br />
                    <input type="text" name="mailing_address" />
                  </label>
                </div>
                <div style={{ display: 'block', margin: '.5rem auto', textAlign: 'left' }}>
                  <label>
                    City: <br />
                    <input type="text" name="city" />
                  </label>

                  <label>
                    State: <br />
                    <input type="text" name="state" />
                  </label>
                  <label>
                    Zip: <br />
                    <input type="number" name="zip" />
                  </label>
                </div>
                <div style={{ display: 'block', margin: '.5rem auto', textAlign: 'left' }}>
                  <label>
                    Corp Officer: <br />
                    <input type="text" name="corp_officer" />
                  </label>
                  <label>
                    Accts Payable Contact: <br />
                    <input type="text" name="accts_payable_contact" />
                  </label>
                </div>
                <div style={{ display: 'block', margin: '.5rem auto', textAlign: 'left' }}>
                  <label>
                    Proprietorship:
                    <input
                      type="radio"
                      value="proprietorship"
                      name="proprietorship"
                      checked={'proprietorship' === radioValue1}
                      onChange={handleRadioChange1}
                    />
                  </label>
                  <label>
                    Partnership:
                    <input
                      type="radio"
                      value="partnership"
                      name="partnership"
                      checked={'partnership' === radioValue1}
                      onChange={handleRadioChange1}
                    />
                  </label>
                  <label>
                    LLC:
                    <input
                      type="radio"
                      value="LLC"
                      name="LLC"
                      checked={'LLC' === radioValue1}
                      onChange={handleRadioChange1}
                    />
                  </label>
                  <label>
                    Corp:
                    <input
                      type="radio"
                      value="corp"
                      name="corp"
                      checked={'corp' === radioValue1}
                      onChange={handleRadioChange1}
                    />
                  </label>
                  <label>
                    Non-Profit:
                    <input
                      type="radio"
                      value="non_profit"
                      name="non_profit"
                      checked={'non profit' === radioValue1}
                      onChange={handleRadioChange1}
                    />
                  </label>
                </div>

                <div style={{ display: 'block', margin: '.5rem auto', textAlign: 'left' }}>
                  <fieldset>
                    Business Property:
                    <label>
                      Own:
                      <input
                        type="radio"
                        value="own"
                        name="own"
                        checked={'own' === radioValue2}
                        onChange={handleRadioChange2}
                      />
                    </label>
                    <label>
                      Corp:
                      <input
                        type="radio"
                        value="rent"
                        checked={'rent' === radioValue2}
                        onChange={handleRadioChange2}
                      />
                    </label>
                  </fieldset>
                  {/*<label>*/}
                  {/*  Business Property:*/}
                  {/*  <br />*/}
                  {/*  <br />*/}
                  {/*  Own:*/}
                  {/*  <input*/}
                  {/*    type="radio"*/}
                  {/*    value="own"*/}
                  {/*    checked={'own' === radioValue2}*/}
                  {/*    onChange={handleRadioChange2}*/}
                  {/*  />*/}
                  {/*</label>*/}
                  {/*<label>*/}
                  {/*  Corp:*/}
                  {/*  <input*/}
                  {/*    type="radio"*/}
                  {/*    value="rent"*/}
                  {/*    checked={'rent' === radioValue2}*/}
                  {/*    onChange={handleRadioChange2}*/}
                  {/*  />*/}
                  {/*</label>*/}
                </div>

                <h3>Equipment</h3>
                <div style={{ display: 'block', margin: '.5rem auto', textAlign: 'left' }}>
                  <label>
                    Description: <br />
                    <input type="text" name="description" />
                  </label>
                </div>

                <div style={{ display: 'block', margin: '.5rem auto', textAlign: 'left' }}>
                  <label>
                    Physical address: <br />
                    <input type="text" name="physical_address" />
                  </label>
                  <label>
                    City: <br />
                    <input type="text" name="equipment_city" />
                  </label>
                  <label>
                    State: <br />
                    <input type="text" name="equipment_state" />
                  </label>
                  <label>
                    Zip: <br />
                    <input type="text" name="equipment_zip" />
                  </label>
                </div>

                <div style={{ display: 'block', margin: '.5rem auto', textAlign: 'left' }}>
                  <label>
                    Total Cost: <br />
                    <input type="text" name="equipment_total_cost" />
                  </label>
                  <label>
                    Term
                    <input
                      type="radio"
                      value="term"
                      name="term"
                      checked={'term' === radioValue3}
                      onChange={handleRadioChange3}
                    />
                  </label>
                  <label>
                    12mo
                    <input
                      type="radio"
                      value="12mo"
                      name="12mo"
                      checked={'12mo' === radioValue3}
                      onChange={handleRadioChange3}
                    />
                  </label>
                  <label>
                    24mo
                    <input
                      type="radio"
                      value="24mo"
                      name="24mo"
                      checked={'24mo' === radioValue3}
                      onChange={handleRadioChange3}
                    />
                  </label>
                  <label>
                    36mo
                    <input
                      type="radio"
                      value="36mo"
                      name="36mo"
                      checked={'36mo' === radioValue3}
                      onChange={handleRadioChange3}
                    />
                  </label>
                  <label>
                    48mo
                    <input
                      type="radio"
                      value="48mo"
                      name="48mo"
                      checked={'48mo' === radioValue3}
                      onChange={handleRadioChange3}
                    />
                  </label>
                  <label>
                    60mo
                    <input
                      type="radio"
                      value="60mo"
                      name="60mo"
                      checked={'60mo' === radioValue3}
                      onChange={handleRadioChange3}
                    />
                  </label>
                  <br />
                  <label>*Purchase option at end of lease $1.00</label>
                </div>

                <h3>Equipment Supplier</h3>

                <div style={{ display: 'block', margin: '.5rem auto', textAlign: 'left' }}>
                  <label>
                    Company Name: <br />
                    <input type="text" name="company_name" />
                  </label>
                </div>
                <div style={{ display: 'block', margin: '.5rem auto', textAlign: 'left' }}>
                  <label>
                    Representative: <br />
                    <input type="text" name="representative" />
                  </label>
                  <label>
                    Phone: <br />
                    <input type="text" name="company_phone" />
                  </label>
                  <label>
                    Fax: <br />
                    <input type="text" name="company_fax" />
                  </label>
                </div>

                <h3>Personal Information</h3>

                <div style={{ display: 'block', margin: '.5rem 0', textAlign: 'left' }}>
                  <label>
                    Owner #1: <br />
                    <input type="text" name="owner_1" />
                  </label>
                  <label>
                    Owner #2: <br />
                    <input type="text" name="owner_2" />
                  </label>
                </div>
                <div style={{ display: 'block', margin: '.5rem 0', textAlign: 'left' }}>
                  <label>
                    Owner #1 - Home Address: <br />
                    <input type="text" name="owner1_home_address" />
                  </label>
                  <label>
                    Owner #2 - Home Address: <br />
                    <input type="text" name="owner2_home_address" />
                  </label>
                </div>

                <div style={{ display: 'block', margin: '.5rem 0', textAlign: 'left' }}>
                  <label>
                    Owner #1 - City: <br />
                    <input type="text" name="owner1_city" />
                  </label>
                  <label>
                    Owner #1 - State: <br />
                    <input type="text" name="owner1_state" />
                  </label>
                  <label>
                    Owner #1 - Zip: <br />
                    <input type="number" name="owner1_zip" />
                  </label>
                </div>
                <div style={{ display: 'block', margin: '.5rem 0', textAlign: 'left' }}>
                  <label>
                    Owner #2 - City: <br />
                    <input type="text" name="owner2_ity" />
                  </label>
                  <label>
                    Owner #2 - State: <br />
                    <input type="text" name="owner2_state" />
                  </label>
                  <label>
                    Owner #2 - Zip: <br />
                    <input type="number" name="owner2_zip" />
                  </label>
                </div>

                <div style={{ display: 'block', margin: '.5rem 0', textAlign: 'left' }}>
                  <label>
                    Owner #1 - Email <br />
                    <input type="email" name="owner1_email" />
                  </label>
                  <label>
                    Owner #2 - Email <br />
                    <input type="email" name="owner2_email" />
                  </label>
                </div>

                <div className={'button-div'}>
                  <button className={'Services__blueButton'} type="submit">
                    Submit
                  </button>
                </div>
              </section>
            </form>
          </FormWrapper>
        </div>
      </Layout>
    </>
  );
};

export default RestaurantEquipment;

export const pageQuery = graphql`
  query {
    strapiSsRestaurantEquip {
      id
      MetaData {
        id
        title
        metaKeywords
        metaDescription
      }
      title
      sectionUnderHero
      hero_image {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      description
      secondSectionTitle
      secondSection
      thirdSection
    }
  }
`;
